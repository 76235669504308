<template>
  <a-modal
      :width="1000"
      v-model="showPopup"
      :title="titleText"
      @cancel="resetForms"
      @ok="handleConfirm"
  >
    <div class="content-popup">
      <a-form-model :model="params" v-bind="layout">
<!--    版别信息    -->
        <a-form-item label="版别信息">
          <!-- 选择大币种 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="changeCoin"
              @search="coinSearch"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="w-150 ml-5"
              v-model="params.coinKindId"
              placeholder="请选择大币种"
          >
            <a-select-option
                v-for="item of coinList"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindName }}</a-select-option>
          </a-select>
          <!-- 选择小币种 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="changeCoinItem"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="w-150 ml-5"
              v-model="params.coinKindItemId"
              placeholder="请选择小币种"
          >
            <a-select-option
                v-for="item of coinItemList"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
          <!-- 选择版别 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              showSearch
              :filter-option="untils.filterOption"
              class="w-250 ml-5"
              v-model="params.coinKindItemVersionId"
              :dropdownMenuStyle="{'max-height': '500px'}"
              placeholder="版别"
          >
            <a-select-option
                v-for="item of versionList"
                :key="item.coinVersionId"
                :value="item.coinVersionId"
            >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
          </a-select>
        </a-form-item>
<!--    图片类型    -->
        <a-form-item label="图片性质">
          <a-select
              allowClear
              showSearch
              class="w-150 ml-5"
              v-model="params.imgNatureType"
              placeholder="性质"
          >
            <a-select-option
                v-for="item of natureTypeList"
                :key="item.id"
                :value="item.id"
            >{{ item.natureName }}</a-select-option>
          </a-select>
        </a-form-item>
<!--    图库标题    -->
        <a-form-item label="图片标题">
          <a-input
              allowClear
              v-model="params.imgTitle"
              class="ml-5"
              placeholder='图片标题'
          ></a-input>
        </a-form-item>
<!--    数据    -->
        <a-form-item label="钱币数据">
          <div class="flex-start data-input">
            <a-input
                allowClear
                v-model="params.weight"
                class="w-200 ml-5"
                placeholder='质量'
            ></a-input>
            <span class="ml-5">g</span>
            <a-input
                allowClear
                v-model="params.size"
                class="w-200 ml-20"
                placeholder='尺寸'
            ></a-input>
            <span class="ml-5">mm</span>
          </div>
        </a-form-item>
<!--    成交信息    -->
        <a-form-item label="成交信息">
          <a-date-picker
              class="ml-5"
              placeholder="成交时间选择"
              @change="handleSelectedPicker"
          />
          <a-select
              allowClear
              showSearch
              class="w-150 ml-20"
              v-model="params.tradingPlatform"
              placeholder="交易平台"
          >
            <a-select-option
                v-for="item of tradingPlatformTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              allowClear
              v-model="params.tradingPrice"
              class="w-150 ml-20"
              placeholder='交易金额'
          ></a-input>
          <span class="ml-5">元</span>
        </a-form-item>
<!--    评级信息  -->
        <a-form-item label="评级信息">
          <a-select
              allowClear
              showSearch
              class="w-150 ml-5"
              v-model="params.ratingCompanyId"
              placeholder="评级公司"
              @change="handleChangeSelectCompany"
          >
            <a-select-option
                v-for="item of ratingCompanyTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              v-if="params.ratingCompanyId !== 0"
              allowClear
              v-model="params.ratingCode"
              class="w-250 ml-20"
              placeholder='评级编号'
          ></a-input>
        </a-form-item>
<!--   上传图片   -->
        <a-form-item label="上传图片">
          <a-upload
              key="smm0-lsl"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="tempImgList"
              @preview="handlePreview"
              @change="handleChangeCoverList"
          >
            <div>
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
<!--   图片描述   -->
        <a-form-item label="图片描述">
          <a-textarea v-model="params.imgDesc" placeholder="图片描述" :rows="4" />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import _bigOrSmallCoinByVersion from "../../../../../../_mixins/_bigOrSmallCoinByVersion"
import {mapState} from "vuex"
import {
  RATING_COMPANY_TYPE_LIST,
  TRADING_PLATFORM_TYPE_LIST
} from "@/views/cmsPage/coinBookManage/_data"
import {getBookImgType} from "@/axios/apis"

export default {
  components: {},
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      natureTypeList: [],
      tradingPlatformTypeList: TRADING_PLATFORM_TYPE_LIST,
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST,
      // 大币种了列表
      coinList: [],
      // 小币种列表
      coinItemList: [],
      // 版别列表
      versionList: [],
      layout: {
        labelCol: { span: 2 }, //标签占5份  共24份
        wrapperCol: { span: 22 },
      },
      untils: untils,
      titleText: '新增图库',
      showPopup: false,
      tempImgList: [],
      params: {
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinKindItemVersionId: undefined,
        imgNatureType: undefined,
        imgTitle: undefined,
        weight: undefined,
        size: undefined,
        time: undefined,
        tradingPlatform: undefined,
        tradingPrice: undefined,
        imgList: [],
        imgDesc: undefined,
        ratingCompanyId: undefined,
        ratingCode: undefined
      }
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  mounted() {},
  methods: {
    /** 获取图片性质 */
    async getImgTypeList() {
      const res = await getBookImgType();
      this.$loading.hide()
      if (res.status !== "200") return;
      this.natureTypeList = res.data.records;
    },
    async show(data) {
      this.$loading.show()
      await this.getImgTypeList();
      if (data && data.id) {
        this.titleText = '编辑图库'
        this.setData()
      }
      this.showPopup = true
    },
    /** 编辑时设置默认回显 */
    setData() {

    },
    /** 确定添加 */
    async handleConfirm() {
      if (!this.params.coinKindItemVersionId) return this.$message.warn('版别不能为空')
      if (!this.params.imgNatureType) return this.$message.warn('图片性质不能为空')
      if (!this.params.imgTitle) return this.$message.warn('图片标题不能为空')
      if (!this.params.weight) return this.$message.warn('钱币数据质量不能为空')
      if (!this.params.size) return this.$message.warn('钱币数据尺寸不能为空')
      // if (!this.params.tradingPlatform) return this.$message.warn('交易平台不能为空')
      // if (!this.params.tradingPrice) return this.$message.warn('交易金额不能为空')
      // if (this.tempImgList.length < 1) return this.$message.warn('图片不能为空')
      if (!this.params.ratingCompanyId) return this.$message.warn('请选择评级公司')
      if (this.params.ratingCompanyId !== 0 && !this.params.ratingCode) return this.$message.warn('评级编号不能为空')
      this.params.imgList = this.tempImgList.map(el => el.url)
      this.$message.success('成功了');
      this.resetForms()
      this.$emit("success");
    },
    /** 重置内容 */
    resetForms() {
      this.params = {

      }
      this.showPopup = false
    },
    handlePreview(arr, index) {
      const imgArray = arr.map(el => {
        return { img_url: el }
      })
      this.$previewImg({
        list: imgArray,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
    /** 选择时间 */
    handleSelectedPicker(date, dateString) {
      this.params.time = dateString
      console.log(date, dateString);
    },
    /** 上传图片 */
    handleChangeCoverList({fileList}) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.tempImgList = fileList;
    },
    /** 修改评级公司 */
    handleChangeSelectCompany() {
      if (this.params.ratingCompanyId === 0) {
        this.params.ratingCode = undefined
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.data-input {
  span {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
