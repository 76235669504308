var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, title: _vm.titleText },
      on: { cancel: _vm.resetForms, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-popup" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "版别信息" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        allowClear: "",
                        showSearch: "",
                        placeholder: "请选择大币种"
                      },
                      on: { change: _vm.changeCoin, search: _vm.coinSearch },
                      model: {
                        value: _vm.params.coinKindId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "coinKindId", $$v)
                        },
                        expression: "params.coinKindId"
                      }
                    },
                    _vm._l(_vm.coinList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.coinKindName))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        allowClear: "",
                        showSearch: "",
                        placeholder: "请选择小币种"
                      },
                      on: { change: _vm.changeCoinItem },
                      model: {
                        value: _vm.params.coinKindItemId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "coinKindItemId", $$v)
                        },
                        expression: "params.coinKindItemId"
                      }
                    },
                    _vm._l(_vm.coinItemList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.coinKindItemName))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-250 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        dropdownMenuStyle: { "max-height": "500px" },
                        placeholder: "版别"
                      },
                      model: {
                        value: _vm.params.coinKindItemVersionId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "coinKindItemVersionId", $$v)
                        },
                        expression: "params.coinKindItemVersionId"
                      }
                    },
                    _vm._l(_vm.versionList, function(item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.coinVersionId,
                          attrs: { value: item.coinVersionId }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "【" +
                                (item.level ? item.level : "无") +
                                "】 " +
                                item.coinVersionName +
                                "(" +
                                (item.size || 0) +
                                ")"
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "图片性质" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "性质"
                      },
                      model: {
                        value: _vm.params.imgNatureType,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "imgNatureType", $$v)
                        },
                        expression: "params.imgNatureType"
                      }
                    },
                    _vm._l(_vm.natureTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.natureName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "图片标题" } },
                [
                  _c("a-input", {
                    staticClass: "ml-5",
                    attrs: { allowClear: "", placeholder: "图片标题" },
                    model: {
                      value: _vm.params.imgTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "imgTitle", $$v)
                      },
                      expression: "params.imgTitle"
                    }
                  })
                ],
                1
              ),
              _c("a-form-item", { attrs: { label: "钱币数据" } }, [
                _c(
                  "div",
                  { staticClass: "flex-start data-input" },
                  [
                    _c("a-input", {
                      staticClass: "w-200 ml-5",
                      attrs: { allowClear: "", placeholder: "质量" },
                      model: {
                        value: _vm.params.weight,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "weight", $$v)
                        },
                        expression: "params.weight"
                      }
                    }),
                    _c("span", { staticClass: "ml-5" }, [_vm._v("g")]),
                    _c("a-input", {
                      staticClass: "w-200 ml-20",
                      attrs: { allowClear: "", placeholder: "尺寸" },
                      model: {
                        value: _vm.params.size,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "size", $$v)
                        },
                        expression: "params.size"
                      }
                    }),
                    _c("span", { staticClass: "ml-5" }, [_vm._v("mm")])
                  ],
                  1
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "成交信息" } },
                [
                  _c("a-date-picker", {
                    staticClass: "ml-5",
                    attrs: { placeholder: "成交时间选择" },
                    on: { change: _vm.handleSelectedPicker }
                  }),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-20",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "交易平台"
                      },
                      model: {
                        value: _vm.params.tradingPlatform,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "tradingPlatform", $$v)
                        },
                        expression: "params.tradingPlatform"
                      }
                    },
                    _vm._l(_vm.tradingPlatformTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _c("a-input", {
                    staticClass: "w-150 ml-20",
                    attrs: { allowClear: "", placeholder: "交易金额" },
                    model: {
                      value: _vm.params.tradingPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "tradingPrice", $$v)
                      },
                      expression: "params.tradingPrice"
                    }
                  }),
                  _c("span", { staticClass: "ml-5" }, [_vm._v("元")])
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "评级信息" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "评级公司"
                      },
                      on: { change: _vm.handleChangeSelectCompany },
                      model: {
                        value: _vm.params.ratingCompanyId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "ratingCompanyId", $$v)
                        },
                        expression: "params.ratingCompanyId"
                      }
                    },
                    _vm._l(_vm.ratingCompanyTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm.params.ratingCompanyId !== 0
                    ? _c("a-input", {
                        staticClass: "w-250 ml-20",
                        attrs: { allowClear: "", placeholder: "评级编号" },
                        model: {
                          value: _vm.params.ratingCode,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "ratingCode", $$v)
                          },
                          expression: "params.ratingCode"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "上传图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: "smm0-lsl",
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.tempImgList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "图片描述" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "图片描述", rows: 4 },
                    model: {
                      value: _vm.params.imgDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "imgDesc", $$v)
                      },
                      expression: "params.imgDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }