
/** 活谱-图库管理列表-table */
export const coinBookImgStoreListTableColumns = [
    { title: "ID", align: "center", dataIndex: 'id' },
    { title: "大币种", align: "center", dataIndex: 'bigCoin' },
    { title: "小币种", align: "center", dataIndex: 'smallCoin' },
    { title: "版别", align: "center", dataIndex: 'version' },
    { title: "性质", align: "center", scopedSlots: { customRender: "itemNatureSlot" }, width: 200 },
    { title: "图片", align: "center", scopedSlots: {customRender: 'itemImgListSlot'}, width: 200 },
    { title: "图片说明", align: "center", dataIndex: 'imgDesc', width: 200 },
    { title: "成交信息", align: "center", scopedSlots: { customRender: "itemTradeSlot" }},
    { title: "评级信息", align: "center", scopedSlots: { customRender: "itemRateDescSlot" }, width: 200},
    { title: "版权", align: "center", dataIndex: 'productAuthor'},
    { title: "操作人", align: "center", dataIndex: 'sysAdmin' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" } },
]