var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticClass: "w-200",
            attrs: { allowClear: "", placeholder: "快速搜索" },
            model: {
              value: _vm.params.barCode,
              callback: function($$v) {
                _vm.$set(_vm.params, "barCode", $$v)
              },
              expression: "params.barCode"
            }
          }),
          _c(
            "a-select",
            {
              staticClass: "w-150 ml-5",
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                placeholder: "请选择大币种"
              },
              on: { change: _vm.changeCoin, search: _vm.coinSearch },
              model: {
                value: _vm.params.coinKindId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinKindId", $$v)
                },
                expression: "params.coinKindId"
              }
            },
            _vm._l(_vm.coinList, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.coinKindName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "w-150 ml-5",
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                placeholder: "请选择小币种"
              },
              on: { change: _vm.changeCoinItem },
              model: {
                value: _vm.params.coinKindItemId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinKindItemId", $$v)
                },
                expression: "params.coinKindItemId"
              }
            },
            _vm._l(_vm.coinItemList, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.coinKindItemName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "w-250 ml-5",
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                dropdownMenuStyle: { "max-height": "500px" },
                placeholder: "版别"
              },
              model: {
                value: _vm.params.coinKindItemVersionId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinKindItemVersionId", $$v)
                },
                expression: "params.coinKindItemVersionId"
              }
            },
            _vm._l(_vm.versionList, function(item) {
              return _c(
                "a-select-option",
                {
                  key: item.coinVersionId,
                  attrs: { value: item.coinVersionId }
                },
                [
                  _vm._v(
                    _vm._s(
                      "【" +
                        (item.level ? item.level : "无") +
                        "】 " +
                        item.coinVersionName +
                        "(" +
                        (item.size || 0) +
                        ")"
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "w-150 ml-5",
              attrs: { allowClear: "", showSearch: "", placeholder: "性质" },
              model: {
                value: _vm.params.imgNatureType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "imgNatureType", $$v)
                },
                expression: "params.imgNatureType"
              }
            },
            _vm._l(_vm.natureTypeList, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.natureName))]
              )
            }),
            1
          ),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            { staticClass: "ml-20", on: { click: _vm.handleCoinBookByAddImg } },
            [_vm._v("录入数据")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: {
              list: _vm.data,
              pagination: _vm.pagination,
              "img-type-list": _vm.tableImgTypeList
            },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("AddOrEditorPopup", {
        ref: "addOrEditorPopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }