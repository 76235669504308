import instance from "@/axios"


/** 获取活谱-图片管理列表数据 */
export const getListByCoinBook = async (params) => {
    return instance({
        url: '',
        method: 'GET',
        params: params
    })
}

/** 删除-图片管理列表数据 */
export const deleteListByCoinBook = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionComment/commentDelete',
        method: 'POST',
        data: params
    })
}