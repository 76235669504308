<template>
  <a-modal
      v-model="pushModal"
      :width='800'
      title="挪动"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button key="submit" type="primary" @click="confirmPushModal('sample')">挪动到样本库</a-button>
      <a-button key="submit" type="primary" @click="confirmPushModal('bottom')">更改底库版别</a-button>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 220px"
            v-model="curItem.coinKindItemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            showSearch
            @change="changeVersion"
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="curItem.coinKindItemVersionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.id"
              :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.curItem = {};
      }
    },
  },
  methods: {
    async show(data) {
      this.$loading.show()
      this.curItem = JSON.parse(JSON.stringify(data))
      const { coinKindId, coinKindItemId, coinVersionId, coinKindName, coinKindItemName, coinVersionName } = data;
      await this.coinSearch(coinKindName)
      await this.changeCoin();
      if (!coinKindItemId) return;
      this.$set(this.curItem, "coinKindItemId", coinKindItemId);
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
      this.$set(this.curItem, "coinKindItemVersionId", coinVersionId);
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
      this.$loading.hide()
      this.pushModal = true;
    },
    // 大币种改变
    async changeCoin() {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.$set(this.curItem, "coinSid", coin.sid);
      this.$set(this.curItem, "coinKindName", coin.coinKindName);
      await this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem() {
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindItemId) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion() {
      if (!this.curItem.coinKindItemVersionId) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      const {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.curItem;
      const url = type === 'bottom' ? '/dq_admin/duibanConfirm/editConfirmData' : '/dq_admin/duibanConfirm/confirmDataToSampleData'
      const res = await this.axios.post(url, {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      });
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>